import { IStoreContext } from 'Types/IStoreContext';
import IStore from 'Types/IStore';
import SyncHTTP from 'Utils/AxiosRequest/SyncHTTP';
import MSRequest from 'Utils/MSRequest';
import HTTP from 'Types/HTTP';
import { getUolHostUserDataRequest } from 'Services/UolHostUserRequest';
import { useQuery } from '@tanstack/react-query';
import { createContext, useState, PropsWithChildren } from 'react';

const SUPER_ADMIN = [
  273858118, 330729875, 517336264, 582256184, 512260314, 584049210, 269677266, 602457952, 182100743,
  604266690, 255573369
];

const request = MSRequest<IStore[]>(HTTP.GET, '/adm-provisioner/stores', {
  auth: {
    username: 'prov',
    password: 'uolTk232%#!'
  },
  responseDataTransform: (data) =>
    data
      .filter((str) => str.idOrderAppStatus === 2 && str.idOrderStatus === 1)
      .map((store) => ({
        ...store,
        fantasia: store.fantasia || store.pastaLoja
      }))
});

const StoreContext = createContext<IStoreContext>({
  store: {} as IStore,
  SetStore: () => {},
  storeList: [],
  isSuperAdm: false,
  storeIsFetching: true,
  uolHostUser: undefined
});

export const StoreContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [store, changeStore] = useState<IStore>({} as IStore);
  const { data: storeList, isFetching: storeIsFetching } = useQuery(['stores'], request, {
    initialData: []
  });

  const { data: uolHostUser } = useQuery(['hostSession'], getUolHostUserDataRequest, {
    onSuccess: (data) => {
      const products = data.authorizedProducts.map((prod) => prod.name).toString();
      window.hj('identify', data.email, { products });

      window.uolHostVariables = {
        user: {
          ...data,
          authorizedProducts: products
        }
      };
    }
  });

  const SetStore = (newStore: IStore) => {
    const matchStore = storeList?.find((str) => str === newStore);
    if (!matchStore) {
      throw new Error('Store not found');
    }
    localStorage.setItem('idStore', matchStore.idOrder.toString());
    changeStore(matchStore);
    SyncHTTP(matchStore.idEmpresa, matchStore.pastaLoja);
  };

  const isSuperAdm = SUPER_ADMIN.includes(store.idUser);

  return (
    <StoreContext.Provider
      value={{ store, SetStore, storeList, isSuperAdm, storeIsFetching, uolHostUser }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
