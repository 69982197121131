/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

declare global {
  interface Window {
    isChatOpen: () => boolean;
    toggleChat: () => void;
    openChat: () => void;
    closeChat: () => void;
  }
}

export {};

export default class Helper {
  static isInited = false;

  constructor(id: string) {
    if (!Helper.isInited) {
      this.createScript(id);
      Helper.isInited = true;
    }
  }

  private createScript(id: string) {
    (function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.setAttribute('data-id', x);
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement('script'),
      document.getElementsByTagName('script')[0],
      'https://widgets.helper.uol.com.br/v2/script-widget.min.js',
      id
    );
  }
}
